import { assertNever } from "../utils/assertNever";

export type Guide = "admin_guide" | "user_guide" | "api_guide";

export function isGuide(v: string): v is Guide {
  const r = v as Guide;
  if (r === "admin_guide" || r === "user_guide" || r === "api_guide") {
    return true;
  }
  assertNever(r);
  return false;
}

export type MainPath = Guide | "release_notes";

export function isMainPath(v: string): v is MainPath {
  const r = v as MainPath;
  if (r === "release_notes" || isGuide(r)) return true;
  assertNever(r);
  return false;
}
