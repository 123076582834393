export function isSearchScreen(pathname: string): boolean {
  return (
    pathname === "search" || pathname === "/search" || pathname === "/search/"
  );
}

export function is404Screen(pathname: string): boolean {
  return pathname === "404" || pathname === "/404" || pathname === "/404/";
}

export function isHomeScreen(pathname: string): boolean {
  return pathname.length === 0 || pathname === "/";
}
