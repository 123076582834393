import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

const ToggleNightMode = () => {
  const { dark, toggleDark } = useContext(ThemeContext);

  useEffect(() => {
    if (dark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, []);

  return (
    <div className="justify-end align-middle pl-2 ml-2 mr-4 pt-1.5 inline-block">
      <div className="lg:flex text-very-dark-grey dark:text-soft-blue-grey ml-0 mr-0">
        <div className="justify-center items-center inline-block">
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={dark}
              onChange={toggleDark}
            />
            <div className="relative w-16 h-8 bg-very-light-grey text-very-dark-grey dark:text-bright-white dark:bg-medium-dark-grey rounded-full peer-checked:after:translate-x-7 peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-2 after:bg-very-dark-grey after:dark:bg-bright-white after:rounded-full after:h-5 after:w-5 after:transition-all duration-300 ease-in-out">
              {/* Moon icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`w-5 h-5 absolute left-2 top-1.5 transition-opacity duration-300 ease-in-out ${
                  dark ? "opacity-100" : "opacity-0"
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                />
                {/* Sun icon */}
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`w-6 h-6 absolute right-2 top-1 transition-opacity duration-300 ease-in-out ${
                  dark ? "opacity-0" : "opacity-100"
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                />
              </svg>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ToggleNightMode;
