import React from "react";
import { UnreachableError } from "../../utils/error";
import { FooterDummyType } from "./footer.utils";

type Props = {
  data: FooterDummyType;
};

const FooterButton = ({ data }: Props) => {
  if (data.type !== "external") throw new UnreachableError(data.type);

  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="footer-external-links"
      href={data.link}
    >
      {data.title}
    </a>
  );
};
export default FooterButton;
