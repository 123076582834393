import { useSiteMetadataVersions } from "../queries/useSiteMetadataVersions";
import getFormatVersion from "./getFormatVersion";

/* export default function getDisplayVersion(v: string): string {
  return v === useSiteMetadataVersions().latestVersion
    ? `${getFormatVersion(v)} (Latest)`
    : v;
} */

// Define a custom hook
export default function useDisplayVersion(v: string): string {
  // Call the hook within the custom hook
  const { latestVersion } = useSiteMetadataVersions();

  // Return the formatted version string
  return v === latestVersion ? `${getFormatVersion(v)} (Latest)` : v;
}
