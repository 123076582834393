import React, { useState } from "react";
import {
  adocSectionIdDataAttribute,
  adocSectionTitleDataAttribute,
} from "../../const";
import ScrollLink from "../ScrollLink";

type Props = {
  element: Element;
};

const TocMenuItem = ({ element }: Props) => {
  const [isSelected, setIsSelected] = useState(false);

  let className = "";
  switch (element.nodeName) {
    case "H2":
      className = `${
        isSelected ? "toc-element-h2-selected" : "toc-element-h2"
      }`;
      break;
    case "H3":
      className = `${
        isSelected ? "toc-element-h3-selected" : "toc-element-h3"
      }`;
      break;
    case "H4":
      className = `${
        isSelected ? "toc-element-h4-selected" : "toc-element-h4"
      }`;
      break;
  }

  return (
    <ScrollLink
      toId={element.getAttribute(adocSectionIdDataAttribute)!}
      smooth={true}
      hashSpy={true}
      onSetActive={() => setIsSelected(true)}
      onSetInactive={() => setIsSelected(false)}
    >
      <div className={className}>
        {element.getAttribute(adocSectionTitleDataAttribute)}
      </div>
    </ScrollLink>
  );
};
export default TocMenuItem;
