import React, { useContext, useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { buildFacet } from "@coveo/headless";
import { headlessEngine } from "../../coveo/Engine";
import { useCurrentVersion } from "../../contexts/useCurrentVersion";
import { SearchBoxContext } from "../../contexts/SearchBoxContext";
import { getAllFacets } from "./facet.utils";
import { FacetValue } from "@coveo/headless/dist/definitions/controllers/facets/facet/headless-facet";
import { compareVersions } from "../../utils/getIntegralVersion";
import getFormatVersion from "../../utils/getFormatVersion";

type Props = {
  title: string;
  facetName: string;
};

const VersionPickerFacet = ({ facetName }: Props) => {
  const controller = useMemo(
    () =>
      buildFacet(headlessEngine, {
        options: {
          field: facetName,
          facetId: facetName,
          filterFacetCount: false,
          numberOfValues: 9999,
        },
      }),
    [facetName],
  );
  const [state, setState] = useState(controller.state);
  const [allFacets, setAllFacets] = useState<FacetValue[]>([]);

  const currentVersion = useCurrentVersion();

  // searchExecuted - has a search been executed? (boolean)
  // selectedVersion - currently selected version
  // setSelectedVersion - version modifier
  //
  const { searchExecuted, setSelectedVersion, selectedVersion } =
    useContext(SearchBoxContext);

  function versionCompare(a: any, b: any) {
    return compareVersions(b.value, a.value);
  }

  // This hook will run once after the component renders.
  //
  // 1. Get all version facets.
  // 2. Push each version into an array.
  // 3. Sort the array.
  //
  // 📌 Empty dependencies: will run ONCE after component is initially rendered.
  //
  useEffect(() => {
    getAllFacets({
      fields: "docsversion",
      onSuccess(results) {
        const data: FacetValue[] = [];
        results.forEach((result) => {
          data.push({
            state: "idle",
            value: result.value,
            numberOfResults: result.numberOfResults,
          });
        });

        setAllFacets(data.sort((a, b) => {
          const isANumber = /^\d/.test(a.value);
          const isBNumber = /^\d/.test(b.value);
          
          // If one is text and other is number, text comes first
          if (!isANumber && isBNumber) return -1;
          if (isANumber && !isBNumber) return 1;
          
          // If both are text versions, sort alphabetically
          if (!isANumber && !isBNumber) {
            return a.value.localeCompare(b.value);
          }
          
          // If both are numbered versions, use version compare
          return versionCompare(a, b);
        }));
      },
    });
    // controller.sortBy("alphanumeric");
    return controller.subscribe(() => setState(controller.state));
  }, []);

  // 📌 No dependencies: will run after EVERY time component is rendered.
  //
  // This connects the controller to the component and also sets the initial version.
  //
  useEffect(() => {
    console.log("Component mounted. Setting up initial version...");

    const checkIfLoadingComplete = () => {
      if (!controller.state.isLoading) {
        // Once loading is complete, find the initial version and proceed
        const initialVersion = controller.state.values.find(
          (v) => v.value === currentVersion,
        );

        if (initialVersion) {
          controller.toggleSelect(initialVersion);
          setSelectedVersion(initialVersion); // Assuming setSelectedVersion updates the context or state for displaying results.
        } else {
          console.warn("Current version not found in initial facet values.");
        }

        // Clean up the interval once we're done checking
        clearInterval(intervalId);
      }
    };

    // Polling mechanism to wait for loading to be false
    const intervalId = setInterval(checkIfLoadingComplete, 100);

    // Subscribe to controller changes for re-rendering component with updated state.
    const unsubscribe = controller.subscribe(() => setState(controller.state));
    // Unsubscrive when component has been removed from DOM.
    return () => unsubscribe();
  }, []);

  function getSortedVersions(): FacetValue[] {
    // allFacets - counts of results (no search term) only for minor versions
    // state.values - counts of results (with search term) for all versions (including patch)

    // Iterate over minor versions, finding counts for search term.
    //
    const versions: FacetValue[] = [];
    allFacets.forEach((facet) => {
      const result = state.values.find((data) => data.value === facet.value);
      if (result) {
        versions.push({
          value: result.value,
          state: result.state,
          numberOfResults: result.numberOfResults,
        });
      } else {
        versions.push({
          value: facet.value,
          state: facet.state,
          numberOfResults: 0,
        });
      }
    });
    return versions;
  }

  // 📌 No dependencies: will run each time a new search is executed.
  //
  useEffect(() => {
    console.log("Search executed.");

    if (selectedVersion) {
      controller.deselectAll();

      // // 🚨 Select a new version when the version is changed.
      // //
      // This changes the version of the search results.
      controller.toggleSelect(selectedVersion);
      // This updates the version selector.
      setSelectedVersion(selectedVersion);
    }
  }, [searchExecuted]);

  // This is run whenever the version in the selector is changed.
  //
  // value - the (numeric) index of the selected version (starting at 0).
  //
  function changeVersion(value: string) {
    try {
      // Deselect previous and select new version safely
      controller.deselectAll();
      controller.toggleSelect(getSortedVersions()[parseInt(value)]);

      // Update the version selector
      setSelectedVersion(getSortedVersions()[parseInt(value)]);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error during version change:", error.message);
      } else {
        console.error("Unexpected error type:", error);
      }
    }
  }

  return (
    <div className="flex flex-col justify-start items-start text-white dark:text-white version-selection">
      {getSortedVersions().length > 0 && (
        <Select
          getPopupContainer={(trigger) => trigger.parentElement}
          disabled={state.isLoading}
          onChange={changeVersion}
          className="facet-version-select"
          value={
            selectedVersion
              ? getFormatVersion(selectedVersion.value)
              : getFormatVersion(currentVersion)
          }
        >
          {getSortedVersions().map((v, index) => (
            <Select.Option
              disabled={v.numberOfResults === 0}
              key={index}
              className="p-2"
              value={String(index)} // Ensure the value is a string for consistency
            >
              {getFormatVersion(v.value)}{" "}
              {v.numberOfResults === 0
                ? "(No results)"
                : `(${v.numberOfResults})`}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};
export default VersionPickerFacet;
