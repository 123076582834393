export const properHashChangeEventName = "properhashchange";

export function onProperHashChange(fn: () => void) {
  window.addEventListener(properHashChangeEventName, fn);
  return () => window.removeEventListener(properHashChangeEventName, fn);
}

export function patchHistory() {
  if (typeof window === "undefined") {
    return () => {
      return undefined;
    };
  }

  const originalPushState = history.pushState;
  const originalReplaceState = history.replaceState;

  history.pushState = function pushState(...args) {
    const ret = originalPushState.apply(this, args);
    window.dispatchEvent(new Event(properHashChangeEventName));
    return ret;
  };

  history.replaceState = function replaceState(...args) {
    const ret = originalReplaceState.apply(this, args);
    window.dispatchEvent(new Event(properHashChangeEventName));
    return ret;
  };

  const onhashchange = () => {
    window.dispatchEvent(new Event(properHashChangeEventName));
  };

  window.addEventListener("hashchange", onhashchange);

  return () => {
    history.pushState = originalPushState;
    history.replaceState = originalReplaceState;
    window.removeEventListener("hashchange", onhashchange);
  };
}
