import { graphql, useStaticQuery } from "gatsby";

export const useVersionLookup = () => {
  const data = useStaticQuery<{
    allAsciidoc: {
      nodes: Array<{ permalink: string; version: string }>;
    };
  }>(graphql`
    query {
      allAsciidoc {
        nodes {
          permalink
          version
        }
      }
    }
  `);

  const versionLookup: { [key: string]: string[] } = {};

  for (const node of data.allAsciidoc.nodes) {
    if (!Object.prototype.hasOwnProperty.call(versionLookup, node.permalink)) {
      versionLookup[node.permalink] = [];
    }
    if (!versionLookup[node.permalink].includes(node.version)) {
      versionLookup[node.permalink].push(node.version);
    }
  }

  return versionLookup;
};
