import React, { useContext } from "react";
import { Link } from "gatsby";
import { navbarButtons } from "../const";
import { useSiteMetadataVersions } from "../queries/useSiteMetadataVersions";
import { buildPathIndexUrl } from "../utils/buildAppUrl";
import NavbarButton from "./navbar/NavbarButton";
import ToggleNightMode from "./navbar/ToggleNightMode";
import { useLocation } from "../contexts/useLocation";
import documentationLogo from "../images/domino-logo-white.svg";
import { isSearchScreen } from "../utils/screenRouteChecks";
import SearchBox from "./SearchBox";
import { ResponsiveContext } from "../contexts/ResponsiveContext";

const Navbar = ({ version }: { version?: string }) => {
  const { pathname } = useLocation();
  const { latestVersion } = useSiteMetadataVersions();
  const { responsiveState } = useContext(ResponsiveContext);

  return (
    <aside className={`navbar-container w-full hidden`}>
      <div className="navbar-logo-narrow">
        <Link to="/">
          <img
            loading="lazy"
            src={documentationLogo}
            className="nav-logo"
            alt="domino logo"
          />
        </Link>
      </div>
      <div className="flex flex-wrap">
        {/* Links to sections. */}
        <div className="flex-auto shrink-0">
          <div className="h-14 pl-16 navbar-sub-wrapper ">
            <div className="flex flex-row">
              {navbarButtons.map(({ path, label }) => (
                <div key={path} className="lg:block">
                  <NavbarButton
                    key={path}
                    path={path}
                    label={label}
                    route={buildPathIndexUrl({
                      latestVersion,
                      version,
                      path,
                    })}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Search box and light/dark toggle. */}
        <div className="flex-auto w-[420px]" id="search-toggle">
          <div className="h-14 pr-16 navbar-sub-wrapper ">
            <div className="flex flex-row-reverse w-full">
              <div className="inline-block">
                {/* Search box on search results page. */}
                {isSearchScreen(pathname) &&
                  !responsiveState.isMenuActive &&
                  !responsiveState.isSidebarActive && (
                    <SearchBox hideResults={true} isSearchScreen={true} />
                  )}
                {/* Search box on all other pages. */}
                {!isSearchScreen(pathname) && <SearchBox />}
                <ToggleNightMode />
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Navbar;
