export function getIntegralVersion(
  version: string | undefined
): string {
  if (version) {
    const [major, minor = '0'] = version.split('.');
    return (parseInt(major) * 100 + parseInt(minor)).toString();
  }
  return "";
}

export function compareVersions(
  A: string,
  B: string
) {
  return parseInt(getIntegralVersion(A), 10) - parseInt(getIntegralVersion(B), 10);
}
