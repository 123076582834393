import { permalinkPerPath } from "../const";
import { Guide } from "../types/types";

export function normalizePermalink(
  params:
    | {
        permalink?: string;
        guide: Guide;
      }
    | {
        permalink: string;
        guide?: Guide;
      }
) {
  // cannot use destructuring to allow type narrowing
  if (params.guide) {
    return params.permalink ?? permalinkPerPath[params.guide];
  } else {
    return params.permalink;
  }
}
