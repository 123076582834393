import { footerDummyData } from "./footer.utils";
import React from "react";
import FooterButton from "./footerButton";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-container flex flex-row flex-wrap pt-8">
        <div className="links">
          {footerDummyData.map((data, index) => (
            <div key={index} className="flex-none w-32">
              <FooterButton data={data} key={index} />
            </div>
          ))}
        </div>
        <div className="copyright">
          Copyright © 2024 Domino Data Lab. All rights reserved.
        </div>
      </div>
    </div>
  );
};
export default Footer;
