import { useMemo } from "react";
import { DocOverview } from "../templates/appPage";
import { MainPath } from "../types/types";
import { UnreachableError } from "../utils/error";

export function guideTitlePerCurrentPath(currentPath: MainPath | null): string {
  if (!currentPath) return "Domino Documentation";
  switch (currentPath) {
    case "admin_guide":
      return "Domino Administrator's Guide";
    case "api_guide":
      return "Domino APIs";
    case "release_notes":
      return "Release Notes";
    case "user_guide":
      return "Domino User's Guide";
    default:
      throw new UnreachableError(currentPath);
  }
}

export function useDocTrace(params: {
  docs: DocOverview[];
  id: string | undefined;
}) {
  const docTrace = useMemo(() => getDocTrace(params), [params.docs, params.id]);
  return docTrace;
}

/**
 * COMPUTATIONALLY INTENSE FUNCTION.
 * Be sure to memoize it.
 */
function getDocTrace({
  docs,
  id,
}: {
  docs: DocOverview[];
  id: string | undefined;
}): DocOverview[] {
  function work(search: DocOverview[]): boolean {
    for (const doc of search) {
      if (doc.id === id || work(doc.children)) {
        result.push(doc);
        return true;
      }
    }
    return false;
  }

  const result: DocOverview[] = [];
  if (id == null) return result;
  work(docs);
  return result.reverse();
}
