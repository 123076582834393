import { MainPath } from "../types/types";

export function getNameFromGuide(guide: MainPath | null) {
  if (!guide) {
    return "Domino Documentation";
  }
  switch (guide) {
    case "admin_guide":
      return "Admin Guide";
    case "user_guide":
      return "User Guide";
    case "api_guide":
      return "API Guide";
    case "release_notes":
      return "Release Notes";
  }
}
