import React, { createContext, FC, useReducer } from "react";

type initialStateType = {
  isSidebarActive: boolean;
  sidebarToggleCalled: boolean;
  isMenuActive: boolean;
  menuToggleCalled: boolean;
};

interface ContextValue {
  responsiveState: initialStateType;
  dispatch: ({ type }: { type: string }) => void;
}

const initialState = {
  isSidebarActive: false,
  sidebarToggleCalled: false,
  isMenuActive: false,
  menuToggleCalled: false,
};

export const ResponsiveContext = createContext<ContextValue>({
  responsiveState: initialState,
  dispatch: () => {
    return undefined;
  },
});

// @ts-ignore
export const ResponsiveFunctionalityProvider: FC = ({ children }) => {
  const reducer = (state: initialStateType, action: any) => {
    switch (action.type) {
      case "toggleSidebar":
        return {
          isSidebarActive: !state.isSidebarActive,
          sidebarToggleCalled: true,
          isMenuActive: state.isMenuActive && !state.isMenuActive,
          menuToggleCalled: state.isMenuActive && true,
        };
      case "closeSidebar":
        return {
          ...state,
          isSidebarActive: false,
        };
      case "isSidebarTaggled":
        return {
          ...state,
          sidebarToggleCalled: !state.sidebarToggleCalled,
        };
      case "toggleMenu":
        return {
          isMenuActive: !state.isMenuActive,
          menuToggleCalled: true,
          isSidebarActive: state.isSidebarActive && !state.isSidebarActive,
          sidebarToggleCalled: true,
        };
      case "isMenuToggled":
        return {
          ...state,
          menuToggleCalled: !state.menuToggleCalled,
        };
      default:
        return state;
    }
  };

  const [responsiveState, dispatch] = useReducer(reducer, initialState);

  return (
    <ResponsiveContext.Provider value={{ responsiveState, dispatch }}>
      {children}
    </ResponsiveContext.Provider>
  );
};
