import { useState } from "react";
import { useEvent } from "react-use";
import { properHashChangeEventName } from "../utils/patchHistory";

function getHash() {
  if (typeof window === "undefined") return "";
  return location.hash;
}

/**
 * useLocation is not rerendered when hash is changed via history state manipulation.
 * So introducing our own hook that leverages custom window event fired by patched history
 * via `patchHistory` function.
 */
export function useHash() {
  const [hash, setHash] = useState(getHash());
  useEvent(properHashChangeEventName, () => setHash(getHash()));
  return hash;
}
