import { Link } from "gatsby";
import React from "react";

type Props = {
  title: string;
  url: string;
  active: boolean;
  isSearchItem?: boolean;
};

const BreadCrumb = ({ title, url, active, isSearchItem = false }: Props) => {
  return (
    <div className="flex flex-row">
      <div>
        {active ? (
          <span className="breadcrumb dark:dark-breadcrumb">{title}</span>
        ) : isSearchItem ? (
          <div className="text-medium-dark-grey dark:text-soft-blue-grey">
            {title}
          </div>
        ) : (
          <Link
            to={url}
            className="text-medium-dark-grey dark:text-soft-blue-grey"
          >
            {title}
          </Link>
        )}
      </div>
      {!active && (
        <div>
          <span className="text-medium-dark-grey dark:text-soft-blue-grey mx-2">
            {"/"}
          </span>
        </div>
      )}
    </div>
  );
};

export default BreadCrumb;
