export const footerDummyData: FooterDummyType[] = [
  {
    type: "external",
    link: "https://domino.ai/",
    title: "Domino Data Lab",
  },
  {
    type: "external",
    link: "https://tickets.dominodatalab.com/",
    title: "Knowledge Base",
  },
  {
    type: "external",
    link: "https://domino.ai/blog/",
    title: "Data Science Blog",
  },
  {
    type: "external",
    link: "https://university.domino.ai/",
    title: "Training",
  },
];

export type FooterDummyType = {
  type: "external";
  title: string;
  link: string;
};
