import { PageProps } from "gatsby";
import React, { ReactNode } from "react";
import { DocCategory } from "../../gatsby-node-transformer-asciidoc.types";
import Layout from "../components/layout";
import { CurrentPathProvider } from "../contexts/useCurrentPath";
import { useSiteMetadataVersions } from "../queries/useSiteMetadataVersions";
import { getNormalizedVersionFromUrl } from "../utils/getNormalizedVersionFromUrl";
import { parseCurrentPath } from "./appPage.utils";
import { CurrentVersionProvider } from "../contexts/useCurrentVersion";

export type DocOverview = {
  id: string;
  title: string;
  sidebar: string;
  separator: string;
  section: string;
  permalink: string;
  slug: string;
  version: string;
  category: DocCategory;
  children: DocOverview[];
};

/**
 * COMPUTATIONALLY INTENSE FUNCTION.
 * Be sure to memoize it.
 */
export function recursiveFind(
  doc: DocOverview,
  predicate: (doc: DocOverview) => boolean,
): DocOverview | null {
  if (predicate(doc)) return doc;
  for (const child of doc.children) {
    const found = recursiveFind(child, predicate);
    if (found) return found;
  }
  return null;
}

// TODO: get rid of the docs in context https://www.gatsbyjs.com/docs/creating-and-modifying-pages#performance-implications
export type AppPageContext = {
  root: DocOverview | undefined;
  categoryVersions: string[];
  title: string | undefined;
  id: string | undefined;
  apiSchema: string | null;
  permalink: string;
};

export type AppPageProps = PageProps<object, AppPageContext>;

export default function AppPage({
  children,
  location,
  pageContext: { root, title, categoryVersions, id, permalink },
}: Omit<AppPageProps, "children"> & {
  children: ReactNode;
}) {
  const { latestVersion } = useSiteMetadataVersions();
  const currentPath = parseCurrentPath(location.pathname);
  const version = getNormalizedVersionFromUrl(location.pathname, latestVersion);

  return (
    <CurrentPathProvider currentPath={currentPath}>
      <CurrentVersionProvider currentVersion={version}>
        <Layout
          root={root}
          categoryVersions={categoryVersions}
          version={version}
          title={title}
          id={id}
          permalink={permalink}
        >
          {children}
        </Layout>
      </CurrentVersionProvider>
    </CurrentPathProvider>
  );
}
