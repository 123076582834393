import React, { createContext, FC, useState } from "react";
import { FacetValue } from "@coveo/headless/dist/definitions/controllers/facets/facet/headless-facet";

const initialState = {
  showSearchResult: false,
  searchExecuted: false,
  selectedVersion: undefined,
  selectedGuide: undefined,
};

export const SearchBoxContext = createContext<{
  showSearchResult: boolean;
  searchExecuted: boolean;
  selectedVersion: FacetValue | undefined;
  selectedGuide: FacetValue[] | undefined;
  setSearchExecuted: (state: boolean) => void;
  setShowSearchResult: React.Dispatch<any>;
  setSelectedVersion: (version: FacetValue | undefined) => void;
  setSelectedGuide: (version: FacetValue[] | undefined) => void;
}>({
  showSearchResult: initialState.showSearchResult,
  searchExecuted: initialState.searchExecuted,
  selectedVersion: initialState.selectedVersion,
  selectedGuide: initialState.selectedGuide,
  setSearchExecuted: (state: boolean) => {
    return state;
  },
  setShowSearchResult: () => {
    return undefined;
  },
  setSelectedVersion: (version: FacetValue | undefined) => version,
  setSelectedGuide: (version: FacetValue[] | undefined) => version,
});

// @ts-ignore
export const SearchBoxResultShowProvider: FC = ({ children }) => {
  const [showSearchResult, setShowSearchResult] = useState<boolean>(
    initialState.showSearchResult
  );
  const [searchExecuted, setSearchExecuted] = useState<boolean>(
    initialState.searchExecuted
  );
  const [selectedVersion, setSelectedVersion] = useState<
    FacetValue | undefined
  >(initialState.selectedVersion);

  const [selectedGuide, setSelectedGuide] = useState<FacetValue[] | undefined>(
    initialState.selectedGuide
  );

  return (
    <SearchBoxContext.Provider
      value={{
        selectedGuide,
        setSelectedGuide,
        selectedVersion,
        setSelectedVersion,
        showSearchResult,
        setShowSearchResult,
        setSearchExecuted,
        searchExecuted,
      }}
    >
      {children}
    </SearchBoxContext.Provider>
  );
};
