export type Input = {
  original: string[];
  current: string[];
};

export type State = Input;

export type Result<Output> = {
  output:
    | {
        type: "success";
        parsed: Output;
      }
    | { type: "error" };
  state: State;
};

export type Parser<Output> = (input: Input) => Result<Output>;

/**
 * Composes two parsers.
 */
export function compose<T1, T2>(
  fst: Parser<T1>,
  snd: Parser<T2>
): Parser<[T1, T2]> {
  return (input) => {
    const r1 = fst(input);
    if (r1.output.type === "error")
      return {
        output: r1.output,
        state: currentState(input),
      };

    const r2 = snd(r1.state);
    if (r2.output.type === "error")
      return {
        output: r2.output,
        state: currentState(input),
      };

    return {
      output: success([r1.output.parsed, r2.output.parsed]),
      state: r2.state,
    };
  };
}

/**
 * Composes two parsers to return flattened output.
 */
export function composeFlat<Ts extends unknown[], T>(
  fst: Parser<Ts>,
  snd: Parser<T>
): Parser<[...Ts, T]> {
  const composed = c(fst, snd);
  return (input) => {
    const r = composed(input);
    if (r.output.type === "success") {
      const [ts, t] = r.output.parsed;
      return {
        output: success([...ts, t]),
        state: r.state,
      };
    } else {
      return {
        output: r.output,
        state: r.state,
      };
    }
  };
}

/**
 * Handy function that combines functionality of `compose` and `composeFlat`.
 */
export function c<T1, T2>(fst: Parser<T1>, snd: Parser<T2>): Parser<[T1, T2]>;
export function c<T1, T2, T3>(
  fst: Parser<T1>,
  snd: Parser<T2>,
  trd: Parser<T3>
): Parser<[T1, T2, T3]>;
export function c<T1, T2, T3, T4>(
  fst: Parser<T1>,
  snd: Parser<T2>,
  trd: Parser<T3>,
  frh: Parser<T4>
): Parser<[T1, T2, T3, T4]>;
export function c<T1, T2, T3, T4, T5>(
  fst: Parser<T1>,
  snd: Parser<T2>,
  trd: Parser<T3>,
  frh: Parser<T4>,
  fth: Parser<T5>
): Parser<[T1, T2, T3, T4, T5]>;
export function c<T1, T2, T3, T4, T5, T6>(
  fst: Parser<T1>,
  snd: Parser<T2>,
  trd: Parser<T3>,
  frh: Parser<T4>,
  fth: Parser<T5>,
  sth: Parser<T6>
): Parser<[T1, T2, T3, T4, T5, T6]>;
export function c<Ps extends Parser<unknown>[]>(...ps: Ps): Parser<unknown[]> {
  const [fst, snd, ...rest] = ps;
  let r: Parser<unknown[]> = compose(fst, snd);
  for (const p of rest) {
    r = composeFlat(r, p);
  }
  return r;
}

export function map<I, O>(parser: Parser<I>, fn: (arg: I) => O): Parser<O> {
  return (input) => {
    const result = parser(input);
    if (result.output.type === "success") {
      return {
        output: success(fn(result.output.parsed)),
        state: result.state,
      };
    } else {
      return {
        output: result.output,
        state: result.state,
      };
    }
  };
}

export function firstOf<Output>(
  parser: Parser<Output>,
  ...parsers: Array<Parser<Output>>
): Parser<Output> {
  const ps = [parser, ...parsers];
  return (input) => {
    for (const p of ps) {
      const r = p(input);
      if (r.output.type === "error") continue;
      return r;
    }
    return {
      output: error(),
      state: currentState(input),
    };
  };
}

export function optional<Output>(
  parser: Parser<Output>
): Parser<Output | undefined> {
  return firstOf(parser, dummy);
}

export function success<Output>(v: Output):
  | {
      type: "success";
      parsed: Output;
    }
  | { type: "error" } {
  return { type: "success", parsed: v };
}

export function error<Output>():
  | {
      type: "success";
      parsed: Output;
    }
  | { type: "error" } {
  return { type: "error" };
}

export function nextState({ original, current }: Input): State {
  const [, ...rest] = current;
  return {
    original,
    current: rest,
  };
}

export function currentState(input: Input): State {
  return input;
}

export function fromPredicate<Output extends string>(
  predicate: (v: string) => boolean
): Parser<Output> {
  return (input) => {
    const [v] = input.current;
    if (v == null) {
      return {
        output: error(),
        state: currentState(input),
      };
    }
    if (predicate(v)) {
      return {
        output: success(v as Output),
        state: nextState(input),
      };
    } else {
      return {
        output: error(),
        state: currentState(input),
      };
    }
  };
}

export function literal<Output extends string>(
  literal: Output
): Parser<Output> {
  return fromPredicate((v) => v === literal);
}

export const string: Parser<string> = (input) => {
  const [v] = input.current;
  if (v == null)
    return {
      output: error(),
      state: currentState(input),
    };
  return {
    output: success(v),
    state: nextState(input),
  };
};

export const dummy: Parser<undefined> = (input) => {
  return {
    output: success(undefined),
    state: currentState(input),
  };
};

export const eof: Parser<void> = (input) => {
  if (input.current.length > 0) {
    return {
      output: error(),
      state: currentState(input),
    };
  } else {
    return {
      output: success(undefined),
      state: currentState(input),
    };
  }
};
