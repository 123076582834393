import { GatsbyBrowser } from "gatsby";
import React from "react";

// This has been commented out, as it does not look like any of these components are used anywhere..
//import "tw-elements"; // WARN: intentionally importing it in the browser instead of ssr because it relies on `document` being present
import App from "./src/templates/app";
import AppPage, { AppPageContext } from "./src/templates/appPage";

type AppBrowser = GatsbyBrowser<Record<string, unknown>, AppPageContext>;

export const wrapRootElement: AppBrowser["wrapRootElement"] = ({ element }) => (
  <App>{element}</App>
);

export const wrapPageElement: AppBrowser["wrapPageElement"] = ({
  element,
  props,
}) => <AppPage {...props}>{element}</AppPage>;
