import React, { createContext, useContext, useEffect} from "react";
import { MainPath } from "../types/types";
import { SearchBoxContext } from "./SearchBoxContext";

const initialState: MainPath | null = null;

const CurrentPathContext = createContext<MainPath | null>(initialState);

export function CurrentPathProvider({
  currentPath,
  children,
}: {
  currentPath: MainPath | null;
  children?: React.ReactNode;
}) {
  // Remove the useState and just use the prop directly
  const { setSelectedVersion, setSelectedGuide } = useContext(SearchBoxContext);

  // Use useEffect only for side effects (SearchBox updates)
  useEffect(() => {
    if (currentPath) {
      setSelectedVersion(undefined);
      setSelectedGuide(undefined);
    }
  }, [currentPath]);

  return (
    <CurrentPathContext.Provider value={currentPath}>
      {children}
    </CurrentPathContext.Provider>
  );
}

export function useCurrentPath(): MainPath | null {
  return useContext(CurrentPathContext);
}
