import React, { createContext, FC, useContext, useState } from "react";

interface IThemeContext {
  dark: boolean;
  toggleDark: () => void;
}

const defaultState = {
  dark:
    typeof window !== "undefined" && localStorage.getItem("theme") === "dark",
  toggleDark: () => {
    return undefined;
  },
};

export const ThemeContext = createContext<IThemeContext>(defaultState);

// @ts-ignore
export const ThemeProvider: FC = ({ children }) => {
  const [dark, setDark] = useState(defaultState.dark);

  const toggleDark = () => {
    const _theme = dark ? "light" : "dark";
    localStorage.setItem("theme", _theme);
    setDark(!dark);
    if (dark) {
      document.body.classList.remove("dark");
    } else {
      document.body.classList.add("dark");
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggleDark,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
