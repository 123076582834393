import { normalizeVersion } from "./normalizeVersion";
import { parseAppUrl } from "./parseAppUrl";
import { isParserError } from "./parser.error";
import _ from "lodash";

export function getNormalizedVersionFromUrl(
  url: string,
  latestVersion: string
) {
  let version;
  try {
    version = parseAppUrl(url).version;
  } catch (e) {
    if (!isParserError(e)) {
      console.error("Unexpected error during url parsing:", e);
    }
    version = undefined;
  }
  return normalizeVersion(version, latestVersion);
}

export function getNormalizedVersionFromReleaseNoteUrl(
  url: string,
  latestVersion: string
) {
  const slugs = _.trim(url, "/ ").split("/");
  const version = slugs[1] ? slugs[1].split("-").join(".") : "latest";
  const splittedVersion = version.split(".");

  if (splittedVersion.length === 3 && splittedVersion[2] === "0") {
    const splittedVersion = version.split(".");
    return normalizeVersion(
      `${splittedVersion[0]}.${splittedVersion[1]}`,
      latestVersion
    );
  } else {
    return normalizeVersion(version, latestVersion);
  }
}
