export function getDocVersionSlug(
  version = "latest",
  {
    latestVersion,
    forceVersion = false,
  }: { latestVersion: string; forceVersion?: boolean }
) {
  let result;
  if (forceVersion) {
    result = version;
  } else {
    if (version === latestVersion) {
      result = "latest";
    } else {
      result = version;
    }
  }
  return encodeURIComponent(result);
}
