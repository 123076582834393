import React, { createContext, useContext, useEffect, useState } from "react";
import { useCurrentPath } from "./useCurrentPath";
import { useLocation } from "./useLocation";
import { isSearchScreen } from "../utils/screenRouteChecks";

const initialState = "";

const CurrentVersionContext = createContext<string>(initialState);

export function CurrentVersionProvider({
  currentVersion,
  children,
}: {
  currentVersion: string;
  children?: React.ReactNode;
}) {
  const [value, setValue] = useState(currentVersion);
  const location = useLocation();
  const currentPath = useCurrentPath();
  useEffect(() => {
    if (!isSearchScreen(location.pathname)) {
      setValue(currentVersion);
    }
  }, [currentVersion, currentPath]);

  return (
    <CurrentVersionContext.Provider value={value}>
      {children}
    </CurrentVersionContext.Provider>
  );
}

export function useCurrentVersion(): string {
  return useContext(CurrentVersionContext);
}
