import React, { useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { Facet } from "./facet";
import VersionPickerFacet from "./versionPickerFacet";
import { Link } from "gatsby";
import documentationLogo from "../../images/domino-logo-large.png";
import { Layout } from "antd";
const { Sider } = Layout;
const FacetsMenu = () => {
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <Sider
      className={`ant-sider ${
        collapsed ? "ant-layout-sider-collapsed" : "ant-sider-collapse-menu"
      }`}
      style={{
        left: 0,
        top: 0,
        bottom: 0,
        color: "rgba(255, 255, 255, 0.65)",
        position: "fixed",
      }}
      width={350}
      breakpoint="lg"
      collapsedWidth={0}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      collapsed={collapsed}
    >
      <div className={`domino-logo ${collapsed && "collapsed-logo"}`}>
        <Link to="/">
          <img
            loading="lazy"
            src={documentationLogo}
            className="!my-0 !mx-0 lg:!mx-0 w-full cursor-pointer nav-logo"
            alt="domino logo"
          />
        </Link>
      </div>
      <div className="sidebar-leftnav">
        <div
          onClick={() => setShowFilters(!showFilters)}
          className="cursor-pointer text-white md:hidden w-full px-2 text-sm md:text-base font-semibold md:font-bold flex flex-row justify-between items-center"
        >
          <span>Search Filters</span>
          {!showFilters && <MdArrowDropDown size={25} />}
          {showFilters && <MdArrowDropUp size={25} />}
        </div>
        <div
          className={`sidebar-search${showFilters ? "" : " invisible h-0 md:h-auto md:visible"}`}
        >
          <div className="mx-8 mt-2 mb-1 lg:text-base font-bold text-bright-white">Search by:</div>
          <VersionPickerFacet title="Versions" facetName="docsversion" />
          {/*<Facet facetName="docsversion" title="Versions" />*/}
          <Facet facetName="docsguide" title="Guide" />
        </div>
      </div>
    </Sider>
  );
};
export default FacetsMenu;
