import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "prismjs/themes/prism.css";
import React, { ReactNode, useEffect } from "react";
import { ResponsiveFunctionalityProvider } from "../contexts/ResponsiveContext";
import { SearchBoxResultShowProvider } from "../contexts/SearchBoxContext";
import { ThemeProvider } from "../contexts/ThemeContext";
import "../css/index.css";
import { patchHistory } from "../utils/patchHistory";
import "antd/dist/antd.css";

export default function App({
  children,
}: {
  children?: ReactNode | undefined;
}) {
  useEffect(() => {
    const unpatch = patchHistory();
    return unpatch;
  }, []);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     if (location.hash) {
  //       // Added slash to fromPath(some doesn't have hash) tocheck with location pathname which starts with hash.
  //       const pathInfo = redirects.find((path) =>
  //         ("/" + path.fromPath).includes(location.pathname + location.hash)
  //       );
  //       if (pathInfo) {
  //         location.replace(pathInfo.toPath);
  //       }
  //     }
  //   }
  // }, [hash]);

  return (
    <>
      {/* @ts-ignore */}
      <ThemeProvider>
        {/* @ts-ignore */}
        <ResponsiveFunctionalityProvider>
          {/* @ts-ignore */}
          <SearchBoxResultShowProvider>{children}</SearchBoxResultShowProvider>
        </ResponsiveFunctionalityProvider>
      </ThemeProvider>
    </>
  );
}
