import { buildFacet } from "@coveo/headless";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { headlessEngine } from "../../coveo/Engine";
import { useCurrentPath } from "../../contexts/useCurrentPath";
import { getNameFromGuide } from "../../utils/getNameFromGuide";
import { SearchBoxContext } from "../../contexts/SearchBoxContext";
import { FacetValue } from "@coveo/headless/dist/definitions/controllers/facets/facet/headless-facet";
import { getAllFacets } from "./facet.utils";

type Props = {
  title: string;
  facetName: string;
};

export const Facet = ({ facetName }: Props) => {
  const currentPath = useCurrentPath();
  // @ts-ignore TS6133
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allFacets, setAllFacets] = useState<FacetValue[]>([]);
  const { searchExecuted, setSelectedGuide, selectedGuide } =
    useContext(SearchBoxContext);

  const controller = useMemo(
    () =>
      buildFacet(headlessEngine, {
        options: {
          field: facetName,
          facetId: facetName,
        },
      }),
    [facetName],
  );
  const [state, setState] = useState(controller.state);

  useEffect(() => {
    getAllFacets({
      fields: "docsguide",
      onSuccess(results) {
        const data: FacetValue[] = [];
        results.forEach((result) => {
          if (result.value !== "Domino Documentation") {
            data.push({
              state: "idle",
              value: result.value,
              numberOfResults: result.numberOfResults,
            });
          }
        });
        setAllFacets(data);
      },
    });
    return controller.subscribe(() => setState(controller.state));
  }, [facetName]);

  useEffect(() => {
    if (searchExecuted && facetName === "docsguide") {
      if (selectedGuide) {
        selectedGuide.forEach((value) => controller.toggleSelect(value));
      } else {
        const guideFacets: FacetValue[] = [];
        state.values.forEach((value) => {
          if (currentPath && value.value === getNameFromGuide(currentPath)) {
            guideFacets.push(value);
          }
        });
        setSelectedGuide(guideFacets);
      }
    }
  }, [searchExecuted]);

  function toggleGuide(value: FacetValue) {
    if (selectedGuide) {
      if (value.state === "selected") {
        const selectedGuides = selectedGuide.filter(
          (guide) => guide.value != value.value,
        );
        setSelectedGuide(selectedGuides);
      } else {
        const selectedGuides = selectedGuide;
        selectedGuides.push(value);
        setSelectedGuide(selectedGuides);
      }
    }
    controller.toggleSelect(value);
  }

  return (
    <div className="flex flex-col justify-start items-start mx-8 mt-2 mb-1">
      {state.values.length === 0 && (
        <span className="facet-title">No filters available at the moment</span>
      )}
      {state.values.map((value, index) => {
        // TODO: remove when Coveo index is updated
        if (value.value === "Domino Documentation") {
          return;
        }
        return (
          <div className="flex flex-row items-center my-1.5" key={value.value}>
            <input
              className="w-3 h-3 lg:w-4 lg:h-4"
              id={`_${facetName}-checkbox-${value.value}-${index}`}
              type="checkbox"
              checked={controller.isValueSelected(value)}
              onChange={() => toggleGuide(value)}
              disabled={state.isLoading || value.numberOfResults === 0}
            />

            <label
              className="text-xs lg:text-sm cursor-pointer ml-1.5 checkbox-label"
              htmlFor={`_${facetName}-checkbox-${value.value}-${index}`}
            >
              {/*TODO: Same regarding TODO "hacks" comments*/}
              {value.value.includes("Api")
                ? value.value.replace("Api", "API")
                : value.value}{" "}
              ({value.numberOfResults} results)
            </label>
          </div>
        );
      })}
    </div>
  );
};
