// This is called from getUrlForChangedVersion() in components/sidebar/sidebar.utils.ts.
//
export function checkDocExistenceForVersion(
  version: string,
  permalink: string,
  versionLookup: any,
): boolean {
  // Get versions available for this permalink.
  const versions = versionLookup[permalink];
  // Check if specified version is available.
  return versions.includes(version);
}
