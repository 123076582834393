// TODO: query this info
export const permalinkPerPath = {
  user_guide: "1a73c8",
  admin_guide: "b35e66",
  api_guide: "f35c19",
  release_notes: "41faed",
};

export const titlePerPath = {
  user_guide: "User Guide",
  admin_guide: "Admin Guide",
  api_guide: "API Guide",
  release_notes: "Release Notes",
};

export const labelPerPath = {
  user_guide: "User Guide",
  admin_guide: "Admin Guide",
  api_guide: "API Guide",
  release_notes: "Release Notes",
};

export const navbarButtons = [
  {
    path: "user_guide",
    label: labelPerPath["user_guide"],
    permalink: permalinkPerPath["user_guide"],
  },
  {
    path: "admin_guide",
    label: labelPerPath["admin_guide"],
    permalink: permalinkPerPath["admin_guide"],
  },
  {
    path: "api_guide",
    label: labelPerPath["api_guide"],
    permalink: permalinkPerPath["api_guide"],
  },
  {
    path: "release_notes",
    label: labelPerPath["release_notes"],
    permalink: permalinkPerPath["release_notes"],
  },
] as const;

export const adocLinkDataAttribute = "data-is-adoc-link";
export const adocSectionIdDataAttribute = "data-adoc-section-id";
export const adocSectionTitleDataAttribute = "data-adoc-section-title";
