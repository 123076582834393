import { checkDocExistenceForVersion } from "../../utils/checkDocExistenceForVersion";
import { buildAppUrl, buildGuideIndexUrl } from "../../utils/buildAppUrl";
import { Guide } from "../../types/types";

// This is called from changeSelectedVersion() in ./components/sidebar/sidebar.tsx.
export function getUrlForChangedVersion({
  version,
  latestVersion,
  permalink,
  title,
  currentPath,
  versionLookup,
}: {
  version: string;
  latestVersion: string;
  permalink: string;
  title: string;
  currentPath: Guide;
  versionLookup: any;
}): string {
  const check = checkDocExistenceForVersion(version, permalink, versionLookup);
  return check
    ? buildAppUrl({
        path: currentPath,
        latestVersion,
        permalink: permalink,
        title: title,
        version: version,
      })
    : buildGuideIndexUrl({
        guide: currentPath,
        latestVersion,
        version,
      });
}
